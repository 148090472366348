import { Suspense } from 'react'
import { Container } from '@chakra-ui/react'

// Components
import Loader from '../loader/loader'

function LoaderContainer() {
  return (
    <Container maxW='container.xl'>
      <Loader />
    </Container>
  )
}

export default function LazyLoading({ children }) {
  return <Suspense fallback={<LoaderContainer />}>{children}</Suspense>
}
