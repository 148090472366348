/* eslint-disable react/jsx-filename-extension */
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

// Root components
import Root from './pages/root/root'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <StrictMode>
    <Root />
  </StrictMode>
)
