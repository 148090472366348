import { configureStore } from '@reduxjs/toolkit'

// Slices
import language from './slices/language'
import payment from './slices/payment'

const store = configureStore({
  reducer: { language, payment },
})

export default store
