import { lazy } from 'react'
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react'
import { BrowserRouter, Route } from 'react-router-dom'

// Redux
import { Provider as ReduxProvider } from 'react-redux'
import store from '../../redux/store'

// Components
import VersionLog from '../../components/version-log/version-log'
import LazyLoading from '../../components/lazy-loading/lazy-loading'

// Chakra theme
import { config } from './config-theme'

// Hooks
import useSentryLogging from '../../hooks/use-sentry-logging'

// Components
import SentryRoutes from '../../components/sentry-routes/SentryRoutes'
import {AppRoute} from "../../consts/consts-api";

// Pages
const App = lazy(() => import('../app/app'))
const Success = lazy(() => import('../success/success'))
const Page404 = lazy(() => import('../page-404/page-404'))
const Fail = lazy(() => import('../fail/fail'))
const OrderClosed = lazy(() => import('../order-closed/order-closed'))

export default function Root() {
  const theme = extendTheme({ config })

  // Log errors
  useSentryLogging()

  return (
    <VersionLog>
      <ReduxProvider store={store}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <SentryRoutes>
              <Route
                path='*'
                element={
                  <LazyLoading>
                    <Page404 />
                  </LazyLoading>
                }
              />
              <Route
                path=':externalId'
                element={
                  <LazyLoading>
                    <App />
                  </LazyLoading>
                }
              />
              <Route
                  path={AppRoute.success}
                element={
                  <LazyLoading>
                    <Success />
                  </LazyLoading>
                }
              />
              <Route
                path={AppRoute.fail}
                element={
                  <LazyLoading>
                    <Fail />
                  </LazyLoading>
                }
              />
              <Route
                  path={AppRoute.orderClose}
                element={
                  <LazyLoading>
                    <OrderClosed />
                  </LazyLoading>
                }
              />
            </SentryRoutes>
          </BrowserRouter>
        </ChakraProvider>
      </ReduxProvider>
    </VersionLog>
  )
}
