import { useCallback, useEffect } from 'react'
import { useSearchParams } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { setLang, setLanguages } from '../redux/slices/language'

// Translates
import ruLang from '../locales/ru.json'
import enLang from '../locales/en.json'
import uzsLang from '../locales/uzs.json'
import tryLang from '../locales/try.json'
import inrLang from '../locales/inr.json'
import kztLang from '../locales/kzt.json'
import aznLang from '../locales/azn.json'
import { CurrencyCode } from "../consts/consts-currenc";

const langVariants = {
  ru: ruLang,
  en: enLang,
  uzs: uzsLang,
  try: tryLang,
  inr: inrLang,
  eur: enLang,
  gbr: enLang,
  kzt: kztLang,
  azn: aznLang,
}

const useTranslate = () => {
  const dispatch = useDispatch()
  const lang = useSelector(({language}) => language.lang)
  const languages = useSelector(({language}) => language.languages)
  const [searchParams] = useSearchParams();
  const currency = searchParams.get('currency') ? searchParams.get('currency').toUpperCase() : '';


  // Translate function
  const t = useCallback(
      textKey => (langVariants[lang] ? langVariants[lang][textKey] : langVariants.en[textKey]),
      [lang]
  )

  // Update redux value
  const updateLang = useCallback(
      langKey => dispatch(setLang(langKey)),
      [dispatch]
  )
  const updateLanguages = useCallback(
      langKey => dispatch(setLanguages(langKey)),
      [dispatch]
  )

  // set initial language
  useEffect(() => {
    const inrInit = currency === CurrencyCode.INR ? 'en' : false
    const curLang = (currency === CurrencyCode.RUB || !Object.values(CurrencyCode).includes(currency))
        ? 'ru'
        : currency.toLowerCase();
    const initLang = curLang || 'ru';
    const isHaveLang = localStorage.getItem('lang') === initLang || localStorage.getItem('lang') === 'en'
    updateLang(isHaveLang ? localStorage.getItem('lang') :  inrInit || initLang)
    updateLanguages(initLang)
  }, [currency, updateLanguages, updateLang, searchParams])

  return { t, updateLang, updateLanguages, lang, languages }
}

export default useTranslate
