import { Center } from '@chakra-ui/react'
import { motion } from 'framer-motion'

export default function CenterMotion({ h, children, animation }) {
  return (
    <Center
      h={h}
      initial='initial'
      animate='animate'
      exit='exit'
      variants={animation}
      as={motion.div}
    >
      {children}
    </Center>
  )
}
