import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Text, Stack, keyframes, Button } from '@chakra-ui/react'
import { motion } from 'framer-motion'

// Consts
import { Animation } from '../../consts/consts-anim'

// Components
import CenterMotion from '../center-motion/center-motion'

// Hooks
import useTranslate from '../../hooks/use-translate'
import { TIMER_FOR_AUTO_RELOAD, TIMER_FOR_BUTTON_RELOAD } from '../../consts/consts'

export default function Loader() {
  // Redux
  const isBankSelected = useSelector(state => state.payment.isBankSelected)
  const isOrderReady = useSelector(state => state.payment.isOrderReady)
  const isOrderConfirm = useSelector(state => state.payment.isOrderConfirm)
  const isWaitingCardData = useSelector(
    state => state.payment.isWaitingCardData,
  )

  const { t } = useTranslate()

  const isWaitingRedirect = useMemo(
    () => isBankSelected && isOrderReady && isOrderConfirm,
    [isBankSelected, isOrderConfirm, isOrderReady],
  )

  // Animaion
  const animationKeyframes = keyframes`
      0% {
          transform: scale(0.9);
      }
      50% {
          transform: scale(1);
      }
      100% {
          transform: scale(0.9);
      }
  `
  // const [redirectButton, setRedirectButton] = useState(true)
  useEffect(() => {
    if (isWaitingRedirect) {
      // setTimeout(() => {
      //   setRedirectButton(false)
      // }, TIMER_FOR_BUTTON_RELOAD)
      setTimeout(() => {
        window.location.reload()
      }, TIMER_FOR_AUTO_RELOAD)
    }
  }, [isWaitingRedirect])
  const animation = `${animationKeyframes} 4s ease-in-out infinite`

  return (
    <CenterMotion h="100vh" animation={Animation.OPACITY}>
      <Stack justify="center" align="center" userSelect="none">
        <Spinner
          size="xl"
          thickness="4px"
          speed="0.65s"
          emptyColor="blackAlpha.300"
          color="blue.500"
        />
        {isWaitingRedirect && (
          <>
            <Text style={{ margin: '30px 0' }} as={motion.div} animation={animation} textAlign="center">
              {t('Wait. We process your payment...')}
              <br />
              {t(
                'Reload problem',
              )}
            </Text>
            <Button
              colorScheme="blue"
              // disabled={redirectButton}
              onClick={() => window.location.reload()}
              maxW="400px"
              w="100%"
            >
              {t('Reload')}
            </Button>
          </>
        )}
        {isWaitingCardData && (
          <Text as={motion.div} animation={animation} textAlign="center">
            {t(
              'Wait, within 10 seconds we will provide you with the details for the transfer...',
            )}

          </Text>
        )}
      </Stack>
    </CenterMotion>
  )
}
