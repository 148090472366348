/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react'

import packageInfo from '../../../package.json'

export default function VersionLog({ children }) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`Payment form v${packageInfo.version}`)
  }, [])
  return <>{children}</>
}
