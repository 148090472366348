export const Groups = {
  PAYMENT: '/payment',
}

export const Path = {
  GET_TOKEN: `form/event_stream/auth`,
  ABORT: `${Groups.PAYMENT}/abort`,
  PICK_BANK: `${Groups.PAYMENT}/invoice_pick_bank_name`,
  CONFIRM: `${Groups.PAYMENT}/confirm_invoice_card_transfer`,
  STATUS: `${Groups.PAYMENT}/check_order_status_v2`,
  ORDER_DATA: `${Groups.PAYMENT}/invoice_h2h_order_data`,
  UPLOAD_RECIEPT: `${Groups.PAYMENT}/upload_receipt_form`
}

export const AppRoute = {
  fail: '/fail',
  orderClose: '/order-close',
  success: '/success',
}
