/* eslint-disable import/prefer-default-export */

export const Animation = {
  OPACITY_AND_SCALE: {
    initial: { opacity: 0, scale: 0.8 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: { duration: 0.2, delay: 0.2, ease: [0, 0.71, 0.2, 1.01] },
    },
  },
  OPACITY: {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { duration: 0.2, delay: 0.5 },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2 },
    },
  },
}
