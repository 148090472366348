/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lang: localStorage.getItem('lang') || 'en',
  languages: ['en']
}

const slice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload
      // Сохраняем в localstorage
      localStorage.setItem('lang', state.lang)
    },
    setLanguages: (state, action) => {
      state.languages = [...new Set([...state.languages, action.payload])]
    },
  },
})

export const { setLang, setLanguages } = slice.actions
export default slice.reducer
