/* eslint-disable import/prefer-default-export */

export const Currenc = {
  USD: '$',
  EUR: '€',
  RUB: '₽',
  GBR: '£',
  UZS: 'UZS',
  TRY: '₺',
  INR: '₹',
  KZT: '₸',
  AZN: '₼',
  BYN: 'Br',
  KGS: 'с',
}
export const Currency = {
  USD: 'USD',
  EUR: 'EUR',
  RUB: 'RUB',
  UZS: 'UZS',
  TRY: 'TRY',
  INR: 'INR',
  AZN: 'AZN',
  BYN: 'BYN',
  KGS: 'KGS',
}
export const CurrencyCode = {
  RUB: 'RUB',
  UZS: 'UZS',
  TRY: 'TRY',
  INR: 'INR',
  EUR: 'EUR',
  KZT: 'KZT',
  AZN: 'AZN',
  BYN: 'BYN',
  KGS: 'KGS',
  // GBR: 'GBR',
}
